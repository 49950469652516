import React from "react"
import i18n from "i18next"
import image1 from "../../images/servicesBlockchain/BlockchainNft.png"
import image2 from "../../images/servicesBlockchain/BlockchainMarketplace.png"
import image3 from "../../images/servicesBlockchain/BlockchainSmartcontracts.png"
function InHouseDevelopmentBlockchain() {
  return (
    <section className="p-5 tablet:p-20 bigdesktop:px-24 text-left tablet:mb-48">
      <div className="flex flex-col">
        <h4 className="text-blue-100 font-avenir-black text-12">
          {i18n.t("pageServiceBlockchain.InHouseDevelopment.stitle")}
        </h4>
        <p className="font-bold mt-1 mb-4 font-avenir-black text-gray-600 text-40 bigdesktop:text-48 leading-tight">
          {i18n.t("pageServiceBlockchain.InHouseDevelopment.title")}
        </p>

        <div className="flex flex-col items-center justify-center mt-8 mb-10 tablet:flex-row bigdesktop:gap-3">
          <div className="tablet:relative">
            <img
              src={image1}
              alt="Imagen 1"
              className="h-4/5 desktop:w-11/12 bigdesktop:w-full object-cover"
            />
            <p className="text-20 my-5 font-avenir-black text-gray-600">{i18n.t("pageServiceBlockchain.InHouseDevelopment.stages.stage1")}</p>
          </div>


          <div className="tablet:relative transform tablet:translate-y-56">
            <img
              src={image2}
              alt="Imagen 2"
              className="h-4/5 desktop:w-11/12 bigdesktop:w-full object-cover"
            />
            <p className="text-20 my-5 w-11/12 font-avenir-black text-gray-600">{i18n.t("pageServiceBlockchain.InHouseDevelopment.stages.stage2")}</p>
          </div>



          <div className="tablet:relative">
            <img
              src={image3}
              alt="Imagen 3"
              className="h-4/5 desktop:w-11/12 bigdesktop:w-full object-cover"
            />
            <p className="text-20 my-5 w-11/12 font-avenir-black text-gray-600">{i18n.t("pageServiceBlockchain.InHouseDevelopment.stages.stage3")}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InHouseDevelopmentBlockchain
