import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SpecialistSectionBlockchain from '../components/servicesBlockchain/SpecialistSectionBlockchain'
import WhyChoosingUsBlockchain from '../components/servicesBlockchain/WhyChoosingUsBlockchain'
import SquadInfoBlockchain from '../components/servicesBlockchain/SquadInfoBlockchain'
import InHouseDevelopmentBlockchain from '../components/servicesBlockchain/InHouseDevelopmentBlockchain'
import BannerServicesHunting from '../components/servicesHunting/BannerServicesHunting'
import imagee from "../images/servicesBlockchain/BannerBlockchain.png"
import TextBannerBlochchain from '../components/servicesBlockchain/TextBannerBlockchain'
import BannerBtnBlockchain from '../components/servicesBlockchain/BannerBtnBlockchain'
import { withNamespaces } from "react-i18next";
function servicesBlockchain() {
  return (
    <Layout>
      <SEO title={"services blockchain | Exsis Digital Angels"} description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
      <BannerServicesHunting image={imagee} TextComponent={TextBannerBlochchain} />
      <SpecialistSectionBlockchain />
      <WhyChoosingUsBlockchain />
      <SquadInfoBlockchain />
      <InHouseDevelopmentBlockchain />
      <BannerBtnBlockchain />
    </Layout>
  )
}

export default withNamespaces()(servicesBlockchain)