import React from 'react'
import i18n from 'i18next'
function SpecialistSectionBlockchain() {
  return (
   <section className="flex py-16 px-6 desktop:py-20 desktop:pr-12 bigdesktop:pr-10 my-10 desktop:justify-end leading-none">
      <p className="text-40 bigdesktop:text-48 desktop:w-3/5 desktop:pr-10 bigdesktop:pr-0 text-gray-600 text-left font-avenir-black">
        {i18n.t("pageServiceBlockchain.section1")}
      </p>
   </section>
  )
}

export default SpecialistSectionBlockchain