import React from "react"
import i18n from "i18next"
import whyChoosingImage from "../../images/servicesBlockchain/BlockchainWhychoosingus.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"



function WhyChoosingUsBlockchain() {
  return (
    <section className="whyusdiv flex flex-col-reverse tablet:flex tablet:flex-row p-3 desktop:py-10 desktop:px-24 desktop:mr-1 desktop:mb-10 bigdesktop:justify-between">
      <div className="desktop:w-1/2 bigdesktop:pl-3">
        <h2 className="text-gray-600 font-avenir-black text-40 text-left ">
          {i18n.t("pageServiceBlockchain.whyChoosingUs.title")}
        </h2>
        <p className="text-20  desktop:w-4/5 text-gray-500 text-left mt-6">
          {i18n.t("pageServiceBlockchain.whyChoosingUs.desct1")}
          <br /><br />
          {i18n.t("pageServiceBlockchain.whyChoosingUs.desct2")}
        </p>
      </div>
      <div className="mt-8">
        <img className="" src={whyChoosingImage} alt="whychoose" />
        <div className="flex w-full justify-center">
          <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
        </div>
      </div>
    </section>
  )
}

export default WhyChoosingUsBlockchain
