import React from 'react'
import i18n from "i18next"

function TextBannerBlockchain() {
  return (
    <div className="text-left  pl-5 tablet:pl-24  text-white mb-32 tablet:mb-0">
      {i18n.language === "en" ? (
        <p className="text-20 bigdesktop:text-24 font-avenir-heavy">
          E<span className="text-blue-100">x</span>pansion Services
        </p>
      ) : (
        <p className="text-20 bigdesktop:text-24 font-avenir-heavy">
          Servicios de E<span className="text-blue-100">x</span>
          pansión
        </p>
      )}
      <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
        E<span className="text-blue-100">x</span>sis
        <br />
        <span className="text-relativo">Blockchain</span>
      </p>
    </div>
  )
}

export default TextBannerBlockchain