import React from "react"
import i18n from "i18next"
import { Link } from "gatsby"

function SquadCardBlockchain({ item, index}) {
  return (
    <div className= {`flex flex-col  gap-6 ${index==4 ? "justify-start":"justify-between"} w-full tablet:w-1/2 px-6 desktop:pl-0 desktop:pr-0 py-10 bigdesktop:pl-3`}>
      <div className="tablet:flex gap-5">
      <img src={item.image} alt="icon"  className="w-20 h-w-20"/>
      <h2 className="w-4/5 tablet:w-4/6 text-28 text-left text-gray-600 bigdesktop:w-5/12">
        {item.title}
      </h2>
      </div>

      <p className="text-20 text-left text-gray-500 desktop:w-11/12 bigdesktop:w-4/5">
        {item.desc}
      </p>
      {index === 5 && (
        <p className="text-20 text-left text-gray-500 big bigdesktop:w-4/5 ">
          {item.desc2}
        </p>
      )}
      <div className="desktop:w-fit">
      <Link to="/contact-us">
        <button className="bg-blue-100 px-12 py-3 w-full text-white rounded-lg font-sofiaPro-bold ">
          {i18n.t("pageServiceBlockchain.button")}
        </button>
      </Link>
      </div>
    </div>
  )
}

export default SquadCardBlockchain
