import React from "react"
import i18n from "i18next"
import { Link } from "gatsby"

function BannerBtnBlockchain() {
  return (
    <>
      <div className="hidden tablet:block mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerRequestQuote">
        <div className="flip-card-inner2 contentBannerAcademy  mt-24 mb-24">
          <div className="">
            <p className="titleBannerBC  bigdesktop:w-2/3">
              {i18n.t("pageServiceBlockchain.bannertext")}
            </p>

            <div className="w-fit">
                <button
                  onClick={() => window.location.href = 'https://calendly.com/sebastianjimenez/30min?back=1&month=2024-04'}
                  className="mobile:mt-4 contentButtomMoreAcademy textButtomGetTouch font-sofiaPro-bold"
                  style={{ alignSelf: "center" }}
                >
                  {i18n.t("pageServiceBlockchain.button2")}
                </button>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="tablet:hidden mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card BgBannerRequestQuoteMobile">
        <div className="flip-card-inner2  mt-24 mb-24">
          <div className="">
          <p className="titleBannerBCMobile bigdesktop:w-2/3">
              {i18n.t("pageServiceBlockchain.bannertext")}
            </p>
            <div className="my-10">
                <button
                  onClick={() => window.location.href = 'https://calendly.com/sebastianjimenez/30min?back=1&month=2024-04'}y
                  className="mobile:mt-4 w-full text-16 contentButtomMoreAcademyMobile textButtomGetTouchMobile font-sofiaPro-bold"
                  style={{ alignSelf: "center" }}
                >
                  {i18n.t("pageServiceBlockchain.button2")}
                </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerBtnBlockchain
