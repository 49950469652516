import React, { useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import i18n from "i18next"
import imgen1 from "../../images/servicesExpansion/iconsExsisBlockchain/SmartContractIcon.png"
import imgen2 from "../../images/servicesExpansion/iconsExsisBlockchain/DeFisIcon.png"
import imgen3 from "../../images/servicesExpansion/iconsExsisBlockchain/BlockchainGameDevelopmentIcon.png"
import imgen4 from "../../images/servicesExpansion/iconsExsisBlockchain/SwapsIcon.png"
import imgen5 from "../../images/servicesExpansion/iconsExsisBlockchain/DecentralizedAutonomousOrganizationIcon.png"
import imgen6 from "../../images/servicesExpansion/iconsExsisBlockchain/ExchangesIcon.png"
import SquadCardBlockchain from "./SquadCardBlockchain"


function SquadInfoBlockchain() {
  const { windowWidth } = useContext(LoadingContext)
  const squadInfoBlockchainList = [
    {
      title: i18n.t("pageServiceBlockchain.stages.stage1.title"),
      desc: i18n.t("pageServiceBlockchain.stages.stage1.desc1"),
      image: imgen1,
    },
    {
      title: i18n.t("pageServiceBlockchain.stages.stage2.title"),
      desc: i18n.t("pageServiceBlockchain.stages.stage2.desc2"),
      image: imgen5,
    },
    {
      title: i18n.t("pageServiceBlockchain.stages.stage3.title"),
      desc: i18n.t("pageServiceBlockchain.stages.stage3.desc3"),
      image: imgen3,
    },
    {
      title: i18n.t("pageServiceBlockchain.stages.stage4.title"),
      desc: i18n.t("pageServiceBlockchain.stages.stage4.desc4"),
      image: imgen2,
    },
    {
      title: i18n.t("pageServiceBlockchain.stages.stage5.title"),
      desc: i18n.t("pageServiceBlockchain.stages.stage5.desc5"),
      image: imgen4,
    },
    {
      title: i18n.t("pageServiceBlockchain.stages.stage6.title"),
      desc: i18n.t("pageServiceBlockchain.stages.stage6.desc6"),
      desc2: i18n.t("pageServiceBlockchain.stages.stage6.desc7"),
      image: imgen6,
    },
  ]
  const mobileSquadInfoBlockchainList = [
    squadInfoBlockchainList[0],
    squadInfoBlockchainList[2],
    squadInfoBlockchainList[4],
    squadInfoBlockchainList[1],
    squadInfoBlockchainList[3],
    squadInfoBlockchainList[5],
  ]

  return (
    <div className="flex flex-col tablet:flex-row tablet:flex-wrap desktop:px-24 py-12 ">
      {windowWidth >= 640 ? (
        <>
          <div className="flex">
            <SquadCardBlockchain item={squadInfoBlockchainList[0]} index={0} />
            <SquadCardBlockchain item={squadInfoBlockchainList[1]} index={1} />
          </div>
          <div className="flex">
            <SquadCardBlockchain item={squadInfoBlockchainList[2]} index={2} />
            <SquadCardBlockchain item={squadInfoBlockchainList[3]} index={3} />
          </div>
          <div className="flex items-center">
            <SquadCardBlockchain item={squadInfoBlockchainList[4]} index={4} />
            <SquadCardBlockchain item={squadInfoBlockchainList[5]} index={5} />
          </div>
        </>
      ) : (
        mobileSquadInfoBlockchainList.map((item, index, arr) => {
          return <SquadCardBlockchain key={index} item={item} index={index} />
        })
      )}
    </div>
  )
}

export default SquadInfoBlockchain
